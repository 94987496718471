import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import {
  Navbar,
  Typography,
  IconButton,
} from "@material-tailwind/react";

function StickyNavbar() {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    // Add a listener to close the menu when the window width is >= 960 pixels
    const handleResize = () => {
      if (window.innerWidth >= 960) {
        setOpenNav(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up the listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navList = [
    { link: "Home", path: "home" },
    { link: "About", path: "about" },
    { link: "Work", path: "work" },
    { link: "Contact", path: "contact" },
  ];

  return (
    <Navbar className="rounded-[0px] sticky border-0 z-10 top-0 max-w-full py-2 px-4 lg:px-8 lg:py-4  bg-slate-900 bg-opacity-60 backdrop-blur">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="#"
          className="mr-4 cursor-pointer py-1 font-medium text-[25px] font-kanit"
        >
          MatrixTech
        </Typography>

        <div className="flex items-center gap-4">
          <div className="mr-4 hidden lg:block">
            <div className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 font-lato">
              {navList.map(({ link, path }) => (
                <Link
                  spy={true}
                  smooth={true}
                  offset={-75}
                  key={link}
                  to={path}
                  className="cursor-pointer lock hover:text-gray-400"
                >
                  {link}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>

      {/* Mobile menu */}
      <div className={`lg:hidden ${openNav ? "block" : "hidden"}`}>
        <div className="mt-2 flex flex-col gap-2 font-lato">
          {navList.map(({ link, path }) => (
            <Link
              key={link}
              to={path}
              offset={-50}

              className="cursor-pointer hover:text-gray-400"
              onClick={() => setOpenNav(false)} // Close the menu when a link is clicked
            >
              {link}
            </Link>
          ))}
        </div>
      </div>
    </Navbar>
  );
}

export default StickyNavbar;
