// import { StickyNavbar,Home,Work,About,Contact } from './Components'
import React from 'react';
import Home from './Components/Home/Home'
import StickyNavbar from './Components/StickyNavbar/StickyNavbar'
import About from './Components/About/About'
import Work from './Components/Work/Work'
import Contact from './Components/Contact/Contact'



function App() {

  return (
    
    <div className="bg-[url('./Assets/background1.avif')] bg-repeat-y bg-contain">
      <StickyNavbar />
      <Home />
      <About />
      <Work />
      <Contact />
    </div>

  );
}

export default App;