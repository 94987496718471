import { useState } from "react";
import axios from "axios";

function Faq() {

    const [question, setQuestion] = useState("");

    const data={
        Question:question, 
    }

    const send_question = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("/FAQ/addQuestions", data);
            console.log(response.status);
            if (response.status === 200) {
                setQuestion("");
              }
        } catch (error) {
            console.log(error);
        }

    }

    const [faqs, setFAQs] = useState([
        {
            question: 'What is your portfolio website about?',
            answer: 'My portfolio showcases my work and skills as a web developer.',
        },
        {
            question: 'How can I contact you?',
            answer: 'You can contact me via email at example@example.com.',
        },
        // Add more FAQ items as needed
    ]);

    return (
        <div className="bg-yellow-300 rounded-2xl p-5 text-gray-900 rounded-br-[50px] sm:mb-16 m-5">
            <div className="flex text-center justify-center text-3xl sm:text-5xl font-bold p-5">Frequently Asked Questions</div>
            <ul>
                {faqs.map((faq, index) => (
                    <li key={index}>
                        <button className="font-semibold font-lato">{faq.question}</button>
                        <p className="mb-4 font-lato sm:text-base text-sm">{faq.answer}</p>
                    </li>
                ))}
            </ul>
            <input type="text" value={question} placeholder="Ask your question" onChange={(e) => setQuestion(e.target.value)} className="focus:outline-none bg-transparent border-b-2 border-black w-full mt-3" />
            <button
                onClick={send_question}
                className="shadow bg-indigo-600 hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded-[15px] m-2"
                type="submit"
            >
                Send Question
            </button>
        </div>
    );
}

export default Faq;