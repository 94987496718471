import { useState } from "react";
import ChatApp from "../../Assets/projects/ChatApp.jpg";


function Work() {



    const typeOFSkills = ["FRONTEND", "BACKEND", "DATABASE", "APPLICATION"];

    const [colorIndex, setColorIndex] = useState(0);

    const skillsDetails = [
        {
            id: 0,
            name: "Next",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-line.svg",
            type: "FRONTEND",
        },
        {
            id: 1,
            name: "React",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
            type: "FRONTEND",
        },
        {
            id: 3,
            name: "Material UI",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg",
            type: "FRONTEND",
        },
        {
            id: 4,
            name: "HTML",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
            type: "FRONTEND",
        },
        {
            id: 5,
            name: "CSS",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
            type: "FRONTEND",
        },
        {
            id: 6,
            name: "Tailwindcss",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
            type: "FRONTEND",
        },
        {
            id: 7,
            name: "NodeJs",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg",
            type: "BACKEND",
        },
        {
            id: 8,
            name: "javascript",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
            type: "FRONTEND",
        },
        {
            id: 9,
            name: "MongoDB",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg",
            type: "DATABASE",
        },
        {
            id: 10,
            name: "Flutter",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg",
            type: "APPLICATION",
        },
        {
            id: 11,
            name: "Angular",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg",
            type: "FRONTEND",
        },
        {
            id: 12,
            name: "VUE",
            logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg" ,
            type: "FRONTEND",
        },
        {
            id: 13,
            name: "Express",
            logo:  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg" ,
            type: "BACKEND",
        },
        {
            id: 14,
            name: "MySQL",
            logo:  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg" ,
            type: "DATABASE",
        },
        {
            id: 15,
            name: "Android",
            logo:  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-original.svg" ,
            type: "APPLICATION",
        },
        {
            id: 16,
            name: "GraphQl",
            logo:  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/graphql/graphql-plain.svg" ,
            type: "BACKEND",
        },
        {
            id: 17,
            name: "TypeScript",
            logo:  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg" ,
            type: "FRONTEND",
        },        
        {
            id: 18,
            name: "Firebase",
            logo:  "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg" ,
            type: "DATABASE",
        },
        
        
       


    ];

    const projects = [
        {
            id: 0,
            name: "Chat App",
            projectLogo: ChatApp,
            description: "In this app one can chat with their colleagues, friends, also with random people.",
            technology: "#React #TailwindCss #MongoDB"
        }
    ]


    return (
        <div className="bg-gray-900 text-white bg-opacity-50 backdrop-blur-3xl " id="work">

            <div className="sm:flex text-6xl sm:p-20 py-16 text-center font-kanit font-extrabold">Our Skills</div>
            <div className="sm:flex px-16 ">
                <div className="flex flex-col">
                    {
                        typeOFSkills.map((typeOFSkills, index) => (
                            colorIndex === index ? <div className="sm:m-10 m-2 text-center sm:text-start text-blue-500 cursor-pointer font-lato" onClick={() => setColorIndex(index)} key={index}>{typeOFSkills}</div> : <div className="sm:m-10 m-2 text-center sm:text-start cursor-pointer hover:text-blue-400 font-lato" onClick={() => setColorIndex(index)} key={index}>{typeOFSkills}</div>
                        ))
                    }
                </div>
                <div className="flex flex-wrap sm:px-16">
                    {skillsDetails.map((skillsDetails, index) => (
                        typeOFSkills[colorIndex] === skillsDetails.type ?
                            <div key={index} className="parent group hover:scale-110 duration-200 sm:w-max w-20 h-min m-2 sm:m-3 pt-3 pb-3 sm:pr-10 sm:pl-10 rounded-lg shadow-sm shadow-white bg-gray-700 bg-opacity-30 ">
                                <p className="child opacity-0 group-hover:opacity-100 pb-[10px] flex justify-center sm:text-base text-sm duration-700">{skillsDetails.name}</p>
                                <img className="flex sm:p-0 px-4 justify-center child sm:h-[100px] h-[50px]" src={skillsDetails.logo} />
                            </div>
                            : null
                    ))}
                </div>
            </div>

            <div>
                <div className="text-6xl sm:flex font-kanit p-20 pb-8 font-extrabold text-center ">Projects</div>
                <div className="font-lato p-5 sm:px-20 sm:mb-5 text-sm sm:text-lg  sm:w-2/3 text-gray-300">Following projects showcases our skills and experience through real-world examples of my work. Each project is briefly described with links to code repositories and live demos in it. It reflects my ability to solve complex problems, work with different technologies, and manage projects effectively.</div>
                <div className="flex flex-wrap justify-evenly">
                    {
                        projects.map((project, index) => (
                            <div key={index} className="bg-gray-700 m-5 p-5 bg-opacity-50 flex justify-center sm:w-1/3 rounded-xl" >
                                <img className="h-[100px] m-5 cursor-pointer rounded-xl" src={project.projectLogo} />
                                <div className=" w-1/2 p-5">
                                    <span className="block text-2xl font-dmserifdisplay duration-500">{project.name}</span>
                                    <p className="font-lato text-sm text-gray-400 mb-3">{project.description}</p>
                                    <p>{project.technology}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>


        </div>

    );
}

export default Work;