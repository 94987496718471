import React, { useRef, useEffect } from "react";
import Avatar from "react-avatar";
import { motion, useInView, useAnimation, AnimatePresence } from "framer-motion";
import chiragProfile from "../../Assets/profile/chiragprofile.jpg";
import ayushProfile from "../../Assets/profile/ayushprofile.jpeg";
import { Tilt } from 'react-tilt';



function About() {

    const defaultOptions = {
        reverse: true,  // reverse the tilt direction
        max: 20,     // max tilt rotation (degrees)
        perspective: 1500,   // Transform perspective, the lower the more extreme the tilt gets.
        // scale: 0.9,    // 2 = 200%, 1.5 = 150%, etc..
        speed: 1000,   // Speed of the enter/exit transition
        transition: true,   // Set a transition on enter/exit.
        axis: null,   // What axis should be disabled. Can be X or Y.
        reset: true,    // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }


    const opacityVariants = {
        visible: { opacity: 1, transition: { duration: 1 } },
        hidden: { opacity: 0 }
    };
    const topToBottomVariant = {
        visible: { opacity: 1, y: "0vh", transition: { duration: 1 } },
        hidden: { opacity: 0, y: "-5vh" }
    };
    const bottomToTopVariant = {
        visible: { opacity: 1, y: "0vh", transition: { duration: 1 } },
        hidden: { opacity: 0, y: "5vh" }
    };
    const leftToRightVariant = {
        visible: { opacity: 1, x: "0vh", transition: { duration: 1 } },
        hidden: { opacity: 0, x: "-5vh" }
    };

    const controls = useAnimation();
    const controls1 = useAnimation();

    const aboutUS_Ref = useRef();
    const teamMembers_ref = useRef();
    const whatWeChoose_ref = useRef();
    const teamHeading_ref = useRef();
    const teamline_ref = useRef();
    const services_ref = useRef();
    const whatWeDo_ref = useRef();

    const aboutUS_inView = useInView(aboutUS_Ref);
    const teamMembers_inView = useInView(teamMembers_ref);
    const whatWeChoose_inView = useInView(whatWeChoose_ref);
    const teamHeading_inView = useInView(teamHeading_ref);
    const teamline_inView = useInView(teamline_ref);
    const services_inView = useInView(services_ref);
    const whatWeDo_inView = useInView(whatWeDo_ref);



    useEffect(() => {
        if (aboutUS_inView) { controls.start("visible"); }
        else { controls.start("hidden"); }
    }, [controls, aboutUS_inView]);

    useEffect(() => {
        if (whatWeChoose_inView) { controls.start("visible"); }
        else { controls.start("hidden"); }
    }, [controls, whatWeChoose_inView]);

    useEffect(() => {
        if (teamHeading_inView) { controls.start("visible"); }
        else { controls.start("hidden"); }
    }, [controls, teamHeading_inView]);

    useEffect(() => {
        if (teamline_inView) { controls.start("visible"); }
        else { controls.start("hidden"); }
    }, [controls, teamline_inView]);

    useEffect(() => {
        if (services_inView) { controls1.start("visible"); }
        else { controls1.start("hidden"); }
    }, [controls1, services_inView]);

    useEffect(() => {
        if (teamMembers_inView) { controls.start("visible"); }
        else { controls.start("hidden"); }
    }, [controls, teamMembers_inView]);
    useEffect(() => {
        if (whatWeDo_inView) { controls1.start("visible"); }
        else { controls1.start("hidden"); }
    }, [controls1, whatWeDo_inView]);



    const teamMembersData = [
        {
            id: 0,
            name: 'Ayush Sutariya',
            designation: '', 
            position: 'Full Stack Developer',
            profile: ayushProfile,
        },
        {
            id: 1,
            name: 'Savan Patel',
            designation: 'Founder',
            position: 'Full Stack Developer',
            profile: chiragProfile,
        },


    ];


    return (
        <div className="bg-[url('./Assets/background2.avif')] bg-repeat-y bg-contain " >
            <div className="bg-white text-slate-900 bg-opacity-50 backdrop-blur-3xl" id="about"  >

                <motion.div ref={aboutUS_Ref} animate={controls} initial="hidden" variants={opacityVariants} className="pt-16">
                    <div className="flex justify-center text-7xl font-kanit  font-extrabold">About Us</div>
                    <div className="flex justify-center sm:text-2xl text-center  text-xl font-lato p-5">We are best Web development company since 2020.</div>
                </motion.div>

                <div className="flex flex-row py-32 justify-evenly flex-wrap">
                    <div className="text-left sm:pt-24 pb-5">
                        <motion.div ref={teamHeading_ref} animate={controls} initial="hidden" variants={topToBottomVariant} className="font-dmserifdisplay text-4xl">TEAM MEMBERS</motion.div>
                        <motion.div ref={teamline_ref} animate={controls} initial="hidden" variants={bottomToTopVariant}> Meet Some of Our Creative Members.</motion.div>
                    </div>
                    <div className="flex flex-row-reverse">
                        {teamMembersData.map((data1, index) => (
                            <div key={index} ref={teamMembers_ref} animate={controls} initial="hidden" variants={opacityVariants} className="w-full h-[250px] hover:bg-gray-100 bg-white border bg-opacity-75 rounded-2xl  border-slate-900 p-[10px] m-3 backdrop-blur font-lato shadow-2xl " >
                                <div className="flex justify-center mt-2"><Avatar src={data1.profile} size="90" round={true} className="border border-black" /></div><br />
                                <div className="flex justify-center text-xl font-bold ">{data1.name}</div>
                                <div className="flex justify-center">{data1.designation}</div>
                                <div className="flex justify-center">{data1.position}</div>
                            </div>

                        ))}
                    </div>
                </div>

                <div>
                    <motion.div ref={whatWeDo_ref} animate={controls1} initial="hidden" variants={leftToRightVariant} className="sm:flex text-4xl text-center font-bold p-20 font-dmserifdisplay">What We Do!</motion.div>
                    <AnimatePresence>
                        <motion.div ref={services_ref} className="flex flex-wrap justify-evenly pb-10 sm:pb-20">
                            <Tilt options={defaultOptions} >
                                <div className="group sm:w-96 sm:h-96 shadow-2xl min-h-fit  border border-slate-700 rounded-[10px] p-3 m-5 hover:bg-blue-400  hover:pt-6 duration-500  bg-white" >
                                    <div className="flex justify-center m-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" ><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm10 12c0 .685-.07 1.354-.202 2h-3.853c.121-1.283.129-2.621 0-4h3.853c.132.646.202 1.315.202 2zm-.841-4h-3.5c-.383-1.96-1.052-3.751-1.948-5.278 2.435.977 4.397 2.882 5.448 5.278zm-5.554 0h-2.605v-5.658c1.215 1.46 2.117 3.41 2.605 5.658zm-4.605-5.658v5.658h-2.605c.488-2.248 1.39-4.198 2.605-5.658zm0 7.658v4h-2.93c-.146-1.421-.146-2.577 0-4h2.93zm0 6v5.658c-1.215-1.46-2.117-3.41-2.605-5.658h2.605zm2 5.658v-5.658h2.605c-.488 2.248-1.39 4.198-2.605 5.658zm0-7.658v-4h2.93c.146 1.421.146 2.577 0 4h-2.93zm-4.711-11.278c-.896 1.527-1.565 3.318-1.948 5.278h-3.5c1.051-2.396 3.013-4.301 5.448-5.278zm-6.087 7.278h3.853c-.121 1.283-.129 2.621 0 4h-3.853c-.132-.646-.202-1.315-.202-2s.07-1.354.202-2zm.639 6h3.5c.383 1.96 1.052 3.751 1.948 5.278-2.435-.977-4.397-2.882-5.448-5.278zm12.87 5.278c.896-1.527 1.565-3.318 1.948-5.278h3.5c-1.051 2.396-3.013 4.301-5.448 5.278z" /></svg>
                                    </div>
                                    <div className=" flex justify-center font-bold  text-xl m-2 font-kanit">Web Development</div>
                                    <div className="p-5 font-lato group-hover:text-white duration-500">Our expert developers craft tailor-made websites that align perfectly with your brand identity and business goals. We leverage the latest technologies to create responsive, fast-loading, and user-friendly websites that engage visitors across all devices.</div>
                                </div>
                            </Tilt>

                            <Tilt options={defaultOptions} >
                                <div className="group sm:w-96 sm:h-96 shadow-2xl  min-h-fit  border border-slate-700 rounded-[10px] p-3 m-5 hover:bg-red-400 hover:pt-6 duration-500  bg-white " >
                                    <div className="flex justify-center m-3">
                                        <svg xmlns="http://www.w3.org/2000/svg " width="50" height="50" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 16l-6-2.935v-2.131l6-2.934v2.199l-3.64 1.801 3.64 1.796v2.204zm2-8v2.199l3.64 1.801-3.64 1.796v2.204l6-2.935v-2.131l-6-2.934z" /></svg>
                                    </div>
                                    <div className="flex justify-center font-bold text-xl m-2 font-kanit">Application Development</div>
                                    <div className="p-5 font-lato group-hover:text-white duration-500">Beyond websites, we create web applications that serve specific functions or streamline business processes. From customer portals to data management tools, we've got you covered.</div>
                                </div>
                            </Tilt>

                            <Tilt options={defaultOptions} >
                                <div className="group sm:w-96 sm:h-96 shadow-2xl min-h-fit border border-slate-700 rounded-[10px] p-3 m-5 hover:bg-amber-300  hover:pt-6 duration-500 bg-white" >
                                    <div className="flex justify-center m-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M11.334 14c.584 2.239 2.001 2.547 2.001 4.02 0 1.094-.896 1.98-2.001 1.98-1.104 0-2.015-.887-2.015-1.98 0-1.473 1.432-1.781 2.015-4.02zm12.666-2c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5.205-7.316l-2.641 2.316h2.791l1.085-.935c-.37-.498-.782-.96-1.235-1.381zm3.205 7.316c0-1.527-.354-2.969-.969-4.265l-4.486 3.902c-1.402 1.226-2.126.041-3.911 1.091-.237.141-.486.185-.71.158-.566-.07-1.018-.594-.941-1.216.024-.205.113-.419.267-.633 1.128-1.571.183-2.49 1.553-3.68l4.435-3.86c-1.527-.943-3.317-1.497-5.238-1.497-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10z" /></svg>
                                    </div>
                                    <div className="flex justify-center font-bold text-xl m-2 font-kanit">Graphics Design</div>
                                    <div className="p-5 font-lato group-hover:text-white duration-500">Our design team focuses on creating visually appealing interfaces and intuitive user experiences. We aim to make every interaction a delightful journey for your website visitors.</div>
                                </div>
                            </Tilt>





                        </motion.div>
                    </AnimatePresence>
                </div>





                <div className=" flex flex-col-reverse sm:flex-row justify-center py-20 sm:py-28">
                    <div className="basis-3/4 sm:pl-28 px-14">
                        <div className="text-lg font-kanit" ><b>Experience</b> </div>
                        {<div className="mb-3 font-lato">With years of experience in the industry, we've honed our skills and processes to deliver top-notch results.</div>}

                        <div className="text-lg font-kanit" ><b>Collaborative Approach</b> </div>
                        {<div className="mb-3 font-lato">We believe in working closely with our clients. Your input is invaluable throughout the development process.</div>}

                        <div className="text-lg font-kanit" ><b>Cutting-Edge Technology</b> </div>
                        {<div className="mb-3 font-lato">We stay updated with the latest trends and technologies to provide modern, future-proof solutions.</div>}

                        <div className="text-lg font-kanit" ><b>Client Satisfaction</b> </div>
                        {<div className="mb-3 font-lato">Our success is measured by your satisfaction. We're dedicated to exceeding your expectations. </div>}
                    </div>
                    <motion.div ref={whatWeChoose_ref} animate={controls} initial="hidden" variants={topToBottomVariant} className="sm:flex text-4xl text-center basis-1/3 py-12 font-bold font-dmserifdisplay ">Why choose us?</motion.div>
                </div>
            </div>
        </div>



    );
}

export default About;