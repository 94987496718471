import { MdRoom, MdCall, MdEmail } from "react-icons/md";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import Faq from "../FAQ/Faq";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-scroll";
import { motion, useInView, useAnimation } from "framer-motion";
import { toast } from 'react-toastify';

function Contact() {
  const opacityVariants = {
    visible: { opacity: 1, transition: { duration: 2 } },
    hidden: { opacity: 0 }
  };

  const controls = useAnimation();
  const FAQ_controls = useAnimation();

  const form_ref = useRef();
  const FAQ_ref = useRef();

  const form_inView = useInView(form_ref);
  const FAQ_inview = useInView(FAQ_ref);


  useEffect(() => {
    if (form_inView) { controls.start("visible"); }
    else { controls.start("hidden"); }
  }, [controls, form_inView]);

  useEffect(() => {
    if (FAQ_inview) { controls.start("visible"); }
    else { FAQ_controls.start("hidden"); }
  }, [FAQ_controls, FAQ_inview]);



  const formRef = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");



  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      if (firstName === "") alert("Enter FirstName");
      else if (lastName === "") alert("Enter LastName");
      else if (email === "") alert("Enter Email");

      const data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        message: message,
      };

      const response = await axios.post("https://matrixtech-labs-api.onrender.com/visitors/createVisitor", data);
      if (response.status === 200) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        toast.success("your request submitted successfully")
      }
    }
    catch (err) {
      // alert("User already exist");
      toast(err.response.data.error);
    }
  };

  return (
    <div className=" bg-gray-900 text-white bg-opacity-50 backdrop-blur-3xl" id="contact">
      <div className="flex flex-row justify-around pb-14 flex-wrap">
        <div className=" flex-col">
          <div className="sm:flex text-center text-6xl pt-20 pb-8 font-kanit font-extrabold">Contact Us</div>
          <div className="flex max-w-xl p-5 sm:p-0 sm:text-lg text-sm font-lato text-gray-300">
            We're excited to hear from you and discuss how our web development
            services can bring your digital vision to life. Whether you have a
            specific project in mind or just want to learn more about what we
            can offer, we're here to help.
          </div>
        </div>

        <motion.div ref={form_ref} animate={controls} initial="hidden" variants={opacityVariants} className="max-w-screen-md sm:my-20 m-5 p-5 bg-slate-700 bg-opacity-10 rounded-[25px] backdrop-blur-2xl backdrop-saturate-100   ">
          <form ref={formRef} className="w-full">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  First Name
                </label>
                <input
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  className="appearance-none block w-full bg-transparent text-white border-b  border-gray-500 py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-100 "
                  id="grid-first-name fname"
                  type="text"
                  value={firstName}
                  placeholder="Jane"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                  className="appearance-none block w-full bg-transparent text-white border-b border-gray-500  py-3 px-4 leading-tight focus:outline-none focus:border-gray-100"
                  id="grid-last-name lname"
                  type="text"
                  value={lastName}
                  placeholder="Doe"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email Address
                </label>
                <input
                  id="grid-email email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                  placeholder="Email Address"

                  className="appearance-none block w-full bg-transparent text-white border-b border-gray-500  py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-gray-100"
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Your Message
                </label>
                <textarea
                  name="message"
                  placeholder="Enter your idea"
                  rows="3"
                  id="msg"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="max-h-64 min-h-[50px] appearance-none block w-full bg-transparent text-white border-b border-gray-500  py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-100"
                ></textarea>
              </div>
              <div className="flex justify-between w-full px-3">
                <div className="md:flex md:items-center" />
                <button
                  onClick={sendEmail}
                  className="shadow bg-indigo-600 hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded-[15px]"
                  type="submit"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </motion.div>
      </div>

      <div ref={FAQ_ref} animate={controls} initial="hidden" variants={opacityVariants} className="flex justify-center">
        <Faq />
      </div>

      <div className="flex justify-center">
        <hr className="w-11/12"></hr>
      </div>
      <div className=" text-gray-900 pt-5">

        <div className="flex justify-center text-4xl font-bold text-white font-kanit">Reach Us</div>
        <div className="flex justify-center text-lg text-white font-kanit sm:mt-5 m-5">Feel free to get in touch with us – we're here to assist you with any questions or inquiries you may have.</div>
        <div className="flex flex-row justify-center text-lg font-bold flex-wrap">

          <div className="bg-white h-40 w-52 m-5 flex flex-col justify-center">
            <a href="mailto:matrixtechlabs@gmail.com" target="_blank">
              <div className="mb-3 flex justify-center ">
                <MdEmail size={30} onMouseEnter={(e) => { e.target.style.color = 'gray' }} onMouseLeave={(e) => { e.target.style.color = 'black' }} />
              </div>
              <p className="flex justify-center text-2xl">Email Us</p>
              <div className="text-sm flex justify-center">
                matrixtechlabs@gmail.com
              </div>
            </a>
          </div>

          <div className="bg-white h-40 w-52 m-5 flex flex-col justify-center" >
            <a href="tel:+1234567890">
              <div className="mb-3 flex justify-center ">
                <MdCall size={30} onMouseEnter={(e) => { e.target.style.color = 'gray' }} onMouseLeave={(e) => { e.target.style.color = 'black' }} />
              </div>
              <p className="flex justify-center text-2xl">Call Us</p>
              <div className="text-sm flex justify-center">1234567890</div>
            </a>
          </div>


          <div className="bg-white h-40 w-52 m-5 flex flex-col justify-center">
            <a href="https://www.google.com/maps/place/Opera+Crystal/@21.2623438,72.8379282,16z/data=!4m6!3m5!1s0x3be04924b2a8e171:0xab0efe36094b8411!8m2!3d21.2634277!4d72.839798!16s%2Fg%2F11c5sryz1f?entry=ttu" target="_blank" >
              <div className="mb-3 flex justify-center ">
                <MdRoom size={30} onMouseEnter={(e) => { e.target.style.color = 'gray' }} onMouseLeave={(e) => { e.target.style.color = 'black' }} />
              </div>
              <p className="flex justify-center text-2xl">Visit Us</p>
              <div className="text-sm flex justify-center">Surat, Gujarat</div>
            </a>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex  items-center text-white pl-10 py-5 text-lg">
            &copy; matrixtechlabs
          </div>
          <div className="animate-bounce flex  items-center text-white pr-10 py-5">
            <Link
              spy={true}
              smooth={true}
              offset={-75}
              to="home"
              className="cursor-pointer lock transition-all"
            >
              <BsFillArrowUpCircleFill
                size={40}
                color="white"
                style={{ size: "white", transition: "color 0.3s" }}
                onMouseOver={(e) => (e.currentTarget.style.color = "#4F46E5")}
                onMouseOut={(e) => (e.currentTarget.style.color = "white")}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Contact;
